'use client';

import {startTransition, useEffect} from 'react';
import {REDIRECTED_FROM} from '~/middleware/auth';
import {PATHS} from '~/shared/constants/paths';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useRouter, useSearchParams} from 'next/navigation';

import type {Route} from 'next';

type SupabaseListenerProps = {
	serverAccessToken?: string;
};

export const SupabaseListener = ({serverAccessToken}: SupabaseListenerProps) => {
	const {supabase} = useSupabase();
	const router = useRouter();
	const searchParams = useSearchParams();
	const redirectedFrom = searchParams.get(REDIRECTED_FROM);

	useEffect(() => {
		const {
			data: {subscription},
		} = supabase.auth.onAuthStateChange((event, session) => {
			if (session?.access_token !== serverAccessToken) {
				startTransition(() => {
					router.refresh();
					switch (event) {
						case 'SIGNED_OUT': {
							router.push('/auth/login?logged-out=true');
							break;
						}
						case 'SIGNED_IN': {
							router.push((redirectedFrom || PATHS.JobBoard) as Route);
							break;
						}
						default: {
							break;
						}
					}
				});
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [serverAccessToken, router, supabase, redirectedFrom]);

	return null;
};
