'use client';

import {useState} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

import type {PropsWithChildren} from 'react';

export const QueryProvider = ({children}: PropsWithChildren) => {
	const [client] = useState(
		new QueryClient({
			defaultOptions: {
				queries: {
					refetchOnMount: true,
					staleTime: 10_000,
					refetchOnWindowFocus: true,
					refetchInterval: false,
				},
			},
		}),
	);

	return (
		<QueryClientProvider client={client}>
			{children}
			{process.env.NODE_ENV === 'development' && (
				<ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} position="left" />
			)}
		</QueryClientProvider>
	);
};
