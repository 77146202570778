import {createElement, useCallback, useId} from 'react';
import omit from 'lodash.omit';
import {create} from 'zustand';

import type {ComponentProps, ComponentType, ElementType} from 'react';
import type {SetOptional} from 'type-fest';

type ModalComponentProps = Record<string, unknown> & {onOpenChange?: (open: boolean) => void};
type ModalComponent = ComponentType<ModalComponentProps>;

export type ModalStoreState = {
	modals: Record<string, {Component: ModalComponent; props?: ComponentProps<ModalComponent>}>;
	addModal: <T extends ModalComponent>({
		Component,
		props,
		id,
	}: {
		Component: T;
		props?: ComponentProps<T>;
		id: string;
	}) => void;
	removeModal: (id: string) => void;
};

export const useModalStore = create<ModalStoreState>(set => ({
	modals: {},
	addModal: ({Component, props, id}) =>
		set(state => ({
			...state,
			modals: {
				...state.modals,
				[id]: {Component, props: {...props, open: true} as ComponentProps<ModalComponent>},
			},
		})),
	removeModal: id => {
		set(state => ({
			...state,
			modals: {
				...state.modals,
				[id]: {
					...state.modals[id],
					props: {...state.modals[id].props, open: false} as ComponentProps<ModalComponent>,
				},
			},
		}));
		setTimeout(() => set(state => ({...state, modals: omit(state.modals, id)})), 150);
	},
}));

export const ModalProvider = () => {
	const {modals, removeModal} = useModalStore();

	return Object.entries(modals).map(([id, modal]) =>
		createElement(modal.Component, {
			...modal.props,
			key: id,
			onOpenChange: open => {
				if (!open) removeModal(id);
				if (modal.props && 'onOpenChange' in modal.props && typeof modal.props.onOpenChange === 'function') {
					modal.props.onOpenChange(open);
				}
			},
		}),
	);
};

export const useModal = (id?: string) => {
	const defaultId = useId();
	const modalId = id || defaultId;
	const {addModal, removeModal} = useModalStore();

	const show = useCallback(
		<T extends ModalComponentProps>(
			Component: ElementType,
			props?: SetOptional<T, 'open' | 'onOpenChange'>,
		) => {
			addModal({Component: Component as ModalComponent, props, id: modalId});
		},
		[addModal, modalId],
	);

	const hide = useCallback(() => removeModal(modalId), [modalId, removeModal]);

	return {show, hide};
};
