import type {createServerClient} from '~/shared/services/supabase/server';
import {NextResponse} from 'next/server';

import type {NextMiddlewareResult} from 'next/dist/server/web/types';
import type {NextRequest} from 'next/server';

const LOGIN_PATH = '/auth/login';
export const REDIRECTED_FROM = 'redirected-from';

export const withAuth =
	(
		next: (
			request: NextRequest,
			response: NextResponse<unknown>,
			supabase: Awaited<ReturnType<typeof createServerClient>>,
		) => Promise<NextMiddlewareResult>,
	) =>
	async (
		request: NextRequest,
		response: NextResponse,
		supabase: Awaited<ReturnType<typeof createServerClient>>,
	) => {
		const {
			data: {user},
		} = await supabase.auth.getUser();
		const res = user ? await next(request, response, supabase) : NextResponse.next(response);

		if (request.nextUrl.searchParams.has('logged-out')) {
			return res;
		}

		if (!user && !request.nextUrl.pathname.startsWith(LOGIN_PATH)) {
			const redirectUrl = request.nextUrl.clone();
			redirectUrl.pathname = LOGIN_PATH;
			const redirectedFromUrl =
				request.nextUrl.pathname.at(-1) === '/'
					? request.nextUrl.pathname.slice(0, -1)
					: request.nextUrl.pathname;
			if (redirectedFromUrl && redirectedFromUrl !== '/' && !redirectUrl.searchParams.get(REDIRECTED_FROM)) {
				redirectUrl.searchParams.set(REDIRECTED_FROM, redirectedFromUrl);
			}
			return NextResponse.redirect(redirectUrl);
		}

		if (user && request.nextUrl.pathname.startsWith(LOGIN_PATH)) {
			const redirectUrl = request.nextUrl.clone();
			redirectUrl.pathname = redirectUrl.searchParams.get(REDIRECTED_FROM) || '/';
			redirectUrl.searchParams.delete(REDIRECTED_FROM);
			redirectUrl.searchParams.delete('auth-redirect');
			return NextResponse.redirect(redirectUrl);
		}

		return res;
	};
