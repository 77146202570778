export const PATH_LABELS = {
	'/dashboard': 'Dashboard',
	'/settings': 'Settings',
	'/analytics': 'Analytics',
	'/resumes': 'Resumes',
	'/subscribe': 'Subscribe',
	'/personal-page': 'Personal Page',
	'/job-board': 'Job Board',
	'/login': 'Login',
	'/demo': 'Demo',
} as const;

export const PATHS = {
	Dashboard: '/dashboard',
	Settings: '/settings',
	Analytics: '/analytics',
	Resumes: '/resumes',
	Subscribe: '/subscribe',
	PersonalPage: '/personal-page',
	JobBoard: '/job-board',
	Login: '/login',
	Demo: '/demo',
} satisfies {
	[key: string]: keyof typeof PATH_LABELS;
};
